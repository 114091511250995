@import "~@upsales/components/Utils/colors";

.bookingConfirmedContainer {
	width: 100%;
	max-width: 900px;

	#container {
		height: auto;
		width: 100%;
		display: flex;
		align-content: center;
	}
	#calendarImg {
		font-size: 50px;
		margin-top: 50px;
	}
	#subText {
		line-height: 22px;
		max-width: 350px;
		margin-top: 15px;
	}
	#headerBookingConfirmed {
		text-align: center;
		line-height: 36px;
		margin-top: 20px;
	}

	.information {
		background-color: $super-light-blue;
		padding: 16px;
		display: flex;
		align-items: center;
		column-gap: 12px;
	}

	.bookingInfo {
		.Row {
			padding: 16px;
			align-items: center;
			column-gap: 8px;
		}
	}

	.confirmedBookingTitle {
		display: grid;
		grid-template-columns: auto 64px;
		grid-template-rows: 100%;
		justify-content: center;
		align-items: center;
		column-gap: 8px;
		padding: 20px 6vw;

		.Title {
			white-space: nowrap;
		}
	}

	@media (max-width: 480px) {
		#calendarImg {
			margin-top: 5px;
		}
		#subText {
			margin-bottom: 40px;
		}
	}
}
