@import "../../variables";

.BookingForm {
	padding: 0 20px;
	max-width: 480px;
	width: 100%;

	.Label {
		margin-top: 10px;
		font-weight: bold;
	}

	.BackButton {
		display: inline-flex;
		color: #6b7c93;
		font-size: 14px;
		align-items: center;
		padding: 10px 0;
		margin-bottom: 5px;
		user-select: none;
		justify-content: flex-start;
		width: auto;
		cursor: pointer;

		.Icon {
			margin-right: 10px;
		}

		.BackButtonText {
			display: flex;
		}
	}

	.phone-input {
		border-radius: 2px;
		box-sizing: border-box;

		input {
			padding: 10px;
			border: solid 1px $grey-6;
			box-shadow: inset 1px 1px 2px 0 rgba(30, 37, 46, 0.1);
			width: 100%;
			outline: none;
			border-radius: 2px;

			&:focus {
				border: solid 1px $green;
			}
		}
	}

	.BookingFormConfirmButton {
		width: 100%;
		height: auto;
		padding: 8px;
		margin-top: 30px;

		.BookingFormConfirmButtonTitle {
			font-size: 18px;
			font-weight: bold;
			line-height: 21px;
			padding-bottom: 5px;
		}

		.BookingFormConfirmButtonSubtitle {
			font-size: 14px;
			line-height: 16.41px;
		}
	}
}

@media (max-width: $sm-breakpoint) {
	.BookingForm {
		padding-top: 30px;
	}
}
@media (max-width: $xxs-breakpoint) {
	.BookingForm {
		max-width: 95vw;
		min-width: 95vw;
	}
}
