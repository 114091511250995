@import "~@upsales/components/Utils/colors";
@import "../../variables";

.Error404 {
	display: flex;
	justify-content: center;
	text-align: center;
	width: 100vw;

	#subText {
		font-size: 16px;
		line-height: 22px;
		width: 500px;
		max-width: 100vw;
		margin-top: 10px;
	}
	#sadEvent {
		margin-top: 109px;
		@media (max-width: $sm-breakpoint) {
			margin-top: 100px;
		}
	}

	#companyLogo {
		left: 0 !important;
		position: absolute;
		margin-left: 20px;
		margin-top: 24px;
		height: 50px;
	}

	#headerError {
		line-height: 36px;
		margin-top: 29px;
	}
}
