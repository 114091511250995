@import "../../variables";

.phone-input {
	&.has-error {
		.iti__selected-flag,
		input {
			background-color: $super-light-red;
		}
		.iti__selected-flag {
			border-right: 1px solid $red;
		}
	}
	.iti--allow-dropdown {
		display: block;
	}
	.iti__selected-flag {
		background-color: $grey-4;
		border-color: #ccc;
		border-right: 1px solid $grey-6;
		&:hover {
			background-color: lighten($grey-4, 20%);
		}
	}
	.iti__country-list {
		border-color: $green;
		border-radius: 0 0 4px 4px;
		box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
		z-index: 1010;
		.iti__highlight {
			background-color: $green;
			color: $white;
			.iti__dial-code {
				color: $white;
			}
		}
	}
	.iti__flag {
		border-radius: 2px;
		border: 1px solid rgba($black, 0.2);
	}
	.iti--allow-dropdown {
		input {
			padding-left: 60px;
		}
	}

	&__empty-code {
		.btn-sm {
			padding: 0;
			height: 20px;
		}
	}
}
