@import "../../variables";

.BookingTimePicker {
	white-space: nowrap;
	max-width: 480px;
	width: 100%;

	.appointmentLengthSelector {
		margin: 20px 0px 20px;

		.Title {
			margin-bottom: 6px;
		}

		.ButtonSelect {
			box-sizing: border-box;
			width: 100%;
		}
	}

	.Date {
		color: $black;
		font-size: 12px;
		margin-top: 6px;
		margin-bottom: 12px;
	}

	.timePickerButtons {
		height: 370px;
		overflow: hidden;
		overflow-y: auto;

		@media (max-width: $sm-breakpoint) {
			max-height: unset;
			margin-left: 0px !important;
			margin-right: 0px !important;
		}
	}
}
