@import "~@upsales/components/Utils/colors";

#noAvailableTimesContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 50px;

	#emoji {
		font-size: 50px;
	}

	#headerNoAvailableTimes {
		text-align: center;
		line-height: 36px;
		max-width: 450px;
		padding: 0 14px;
	}

	#subTextNoAvailable {
		line-height: 22px;
		max-width: 400px;
		margin-top: 20px;
	}

	.Text {
		padding: 0 14px;
	}
}
