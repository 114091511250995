@import "~@upsales/components/Utils/colors";

.inactiveCalendarContainer {
	#container {
		display: flex;
		text-align: center;
		width: 100vw;
	}

	#animation {
		margin-top: 120px;
	}

	#headerInactiveCalendar {
		line-height: 36px;
		margin-top: 20px;
		flex-grow: 0;
	}

	#subTextInactiveCalendar {
		line-height: 22px;
		margin-top: 15px;
	}
}
