@import "~@upsales/components/Utils/colors";
@import "../../variables";

.Error500 {
	width: 100vw;
	display: flex;
	text-align: center;
	justify-content: center;
	.subText {
		width: 450px;
		max-width: 100vw;
		margin-top: 25px;
	}
	#errorCode {
		font-size: 100px;
		margin-top: 220px;
		@media (max-width: $sm-breakpoint) {
			margin-top: 100px;
		}
	}
	#headerError500 {
		height: 45px;
	}
}
