@import "../../_variables.scss";

.selectContainer-container {
	max-width: 60%;
	min-width: 270px;
	font-size: 14px;
	order: 1;
	-webkit-order: 1;
	z-index: 100;

	@media (max-width: $xxs-breakpoint) {
		order: 2;
		-webkit-order: 2;
		max-width: 100% !important;
		width: 100%;
	}

	// Legend and timezone-picker merges otherwise
	@media (max-width: $md-breakpoint) {
		max-width: 44%;
	}
	.selectContainer__value-container {
		margin-left: 4px;
	}

	.selectContainer__menu {
		margin-top: 0px;
		border: 1px solid $green;
		border-radius: 0 0 4px 4px;
		border-top: 0;
		box-shadow: none;
	}

	.selectContainer__menu-list {
		overflow-x: hidden;
	}

	.selectContainer__control {
		font-size: 14px;
		cursor: pointer;
	}

	.selectContainer__control:hover {
		border-color: $green;
	}

	.selectContainer__control--is-focused {
		border: 1px solid $green;
		box-shadow: none;
	}

	.selectContainer__control--menu-is-open {
		border-bottom: 0;
		border-radius: 4px 4px 0 0;
	}

	.selectContainer__option:hover {
		background-color: $green;
		color: $white;
	}

	.selectContainer__option--is-focused {
		background-color: $green;
		border-radius: 0px;
		color: $white;
	}

	.selectContainer__option--is-selected {
		border: 1px solid $green;
		border-left: none;
		border-right: none;
		background-color: $white;
		color: $black;
	}
}

.bottomRow {
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
}

.legendWrapper {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	order: 1;
	-webkit-order: 1;
	margin: 10px 0px;

	.availableTime {
		background-color: $white;
	}

	#available {
		margin-left: 10px;
	}

	.Text {
		display: flex;
		align-items: center;

		.Icon {
			margin-right: 6px;
			font-size: 20px;
		}
	}

	@media (max-width: $xxs-breakpoint) {
		order: 1;
		-webkit-order: 1;
		margin-top: 10px;
		margin-left: 10px;
		max-width: 100%;
		width: 100%;
		justify-content: flex-start;
	}

	@media (max-width: $md-breakpoint) {
		#available {
			margin-left: 5px;
		}
	}
}
