@import "../../_variables.scss";
.sideView {
	background-color: $grey-1;
	height: 100%;
	width: 100%;
	box-shadow: 1px 1px 0 0 $grey-6;
	padding-bottom: 10px;
	padding-top: 18px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	#contactInfo {
		display: flex;
		flex-direction: column;
		flex-basis: unset;
		flex-grow: unset;
		flex-shrink: unset;

		.Link {
			font-family: "Roboto";
			font-size: 0.7rem;
			padding-top: 3px;
		}

		.Icon {
			margin-right: 0.5rem;
		}
	}

	#contentWrap {
		padding: 42px 20px 0 4rem;
		width: 22rem;
		min-width: 200px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		#userWrap {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;

			.Title,
			.Text,
			.Link {
				display: inline-block;
			}

			.Avatar {
				margin-right: 16px;
			}
		}
	}

	.contactCard {
		height: 70px;
		width: 100%;
		padding: 0 18px;

		.companyLogo {
			height: 100%;
			max-width: 18rem;
			object-fit: contain;
		}
	}

	#contactTitle {
		margin-bottom: 5px;
		font-size: 12px;
		line-height: 18px;
		color: $grey-10;
	}

	#contactName {
		font-size: 16px;
		line-height: 22px;
		color: $black;
	}

	#contactEmail {
		align-items: center;
	}

	#contactEmailText {
		overflow-wrap: anywhere;
	}

	#description {
		margin-top: 25px;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		overflow-wrap: anywhere;
	}

	#descriptionChosenDate .Text {
		margin-left: 10px !important;
	}

	#descriptionChosenTime .Text {
		margin-left: 10px !important;
	}

	#descriptionTitle {
		margin-bottom: 5px;
	}

	#descriptionTextWrap {
		width: 100%;
		padding-bottom: 10px;
	}

	#descriptionText {
		margin-top: 10px;
		padding-right: 20px;
	}

	#descriptionTitle .Text {
		line-height: 20px;
	}

	#descriptionTime {
		line-height: 20px;
		color: $grey-10;
		margin-left: 5px !important;
	}

	.DescriptionRow {
		width: 100%;
		margin: 5px 0px 10px;

		.Icon {
			color: $grey-10;
		}

		.Text {
			color: $grey-10;
		}
	}

	#contactImage {
		box-shadow: 0 0 0 0.03em $green inset;
	}

	@media (max-width: $sm-breakpoint) {
		align-items: center;

		.contactCard {
			max-width: 520px;
			display: flex;
			justify-content: center;

			&.filler {
				height: 0;
			}
		}

		#contactInfo {
			display: flex;
			flex-direction: column;
		}

		#descriptionChosenDate {
			width: 100%;
		}

		#descriptionChosenTime {
			width: 100%;
		}

		#descriptionTime {
			line-height: 20px;
			color: $grey-10;
		}

		#contentWrap {
			padding: 0 20px;
			padding-top: 30px;
			width: 100%;
			margin: 0;
			max-width: 520px;
		}
	}

	@media (min-width: $lg-breakpoint) {
		max-width: 380px;
		padding-bottom: 10px;
	}
}
