@import "../node_modules/@upsales/components/Utils/colors";

// Breakpoints
$xxs-breakpoint: 380px;
$sm-breakpoint: 800px;
$md-breakpoint: 1024px;
$lg-breakpoint: 1200px;
$xlg-breakpoint: 2000px;

//Border radius
$border-radius: 4px;

//these has to be chanegd manually, they do not like the pre-set variables
:root {
	--past-day: #f6f9fb;
	--selected-day: #1d3d48;
	--disabled-day: #ffff;
	--color: #6b7c93;
}
