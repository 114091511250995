.BookingTimePickerButtonWrap {
	display: block;
	width: 100%;
	position: relative;
	margin-bottom: 10px;
}
.BookingTimePickerButtonWrap .PickTimeButton {
	display: inline-block;
	width: 100%;
	padding: 5px;
	font-size: 16px;
	height: auto;
	transition: all 0.3s ease;
	overflow: hidden;
}

.BookingTimePickerButtonWrap .PickTimeButton.up-btn {
	background-color: transparent;
}

.BookingTimePickerButtonWrap .up-btn.btn-green.btn-lined:hover {
	background-color: #00000026;
	color: unset;
}

.BookingTimePickerButtonWrap .PickTimeButton .up-btn {
	color: unset;
}

.BookingTimePickerButtonWrap.BookingTimePickerButtonWrap--showConfirm .PickTimeButton {
	width: 45%;
	color: unset;
	text-shadow: unset;
}

.BookingTimePickerButtonWrap .ConfirmTimeButtonWrap {
	display: flex;
	width: 100%;
	margin-bottom: 10px;
}
.BookingTimePickerButtonWrap .ConfirmTimeButton {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: calc(55% - 10px);
	margin-left: 10px;
	padding: 5px;
	height: 100%;
	box-sizing: border-box;
	font-size: 16px;
	overflow: hidden;

	animation: slide-in 0.3s ease;
	text-shadow: unset !important;
}

.PickTimeButton {
	animation: show 0.3s ease;
}

.ConfirmTimeButton:hover {
	filter: brightness(85%);
}

.BookingTimePickerButtonWrap.BookingTimePickerButtonWrap--showConfirm .PickTimeButton:hover {
	background-color: #00000026;
}

@keyframes slide-in {
	0% {
		opacity: 0;
		color: transparent;
		width: 0;
		transform: translateX(25px);
	}
}

@keyframes show {
	0% {
		height: 0px;
		opacity: 0;
	}

	100% {
		height: 46px;
	}
}
