@import "../../_variables.scss";

.BookingCalendar {
	width: 100%;
	display: grid;
	justify-content: start;
	grid-template-columns: auto;
	grid-template-rows: 1fr;
	grid-template-areas: "BookingTimePickerWrapper BookingCalendarContent";

	&.showTimePicker {
		grid-template-columns: minmax(max-content, 272px) auto;
		grid-gap: 36px;

		.CalendarHeader {
			&__Title {
				word-spacing: 20px;
			}

			&__Nav--left {
				margin-right: 20px;
			}
		}
	}

	.BookingTimePickerWrapper {
		grid-area: BookingTimePickerWrapper;
		animation: show 0.3s ease;
		width: 100%;
		max-width: 272px;
	}

	.BookingCalendarContent {
		grid-area: BookingCalendarContent;
		max-width: 1200px;
		width: 100%;

		.DropDownButton__text-wrapper {
			font-size: 14px;
			color: #4b5562;
		}

		.SelectDateTitle {
			margin-bottom: 16px;
		}

		.selectDateContainer {
			border-radius: $border-radius;
			border: 1px solid $grey-4;

			.CalendarHeader {
				height: auto;
				background-color: $white;
				padding: 15px 18px;
				display: grid;
				grid-template-rows: 100%;
				grid-template-columns: auto 2rem 2rem;
				grid-template-areas: "title navLeft navRight";
				align-items: center;

				&__Title {
					grid-area: title;
					word-spacing: 0.6rem;
				}

				&__Nav {
					position: static;
					text-align: center;
					padding: 0;
					margin: 0;
					font-size: 1.2rem;
					height: auto;
					width: auto;
					padding: 0.1rem;

					&--left {
						grid-area: navLeft;
						justify-self: start;
					}

					&--right {
						grid-area: navRight;
						justify-self: end;
					}
				}
			}

			.Calendar {
				background-color: $white;

				&__Week-header {
					padding: 5px;
					padding-top: 0;
					font-size: 0.8rem;
				}

				&__Week {
					height: 65px;
				}

				&__Day-header {
					padding: 5px;
					padding-top: 0;
				}

				&__Day {
					border: 1px solid $grey-4;
					padding: 4px 0;
					font-weight: bold;
				}

				&__Day--passed {
					font-weight: normal;
				}

				&__Day--passed {
					background-color: var(--past-day) !important;
					box-shadow: var(--selected-day);
					color: var(--color);
				}

				&__Day--selected {
					background-color: var(--selected-day) !important;
				}
			}
		}
	}
}

@keyframes show {
	0% {
		flex: 0 0 0;
		width: 0px;
		opacity: 0;
	}
	100% {
		flex: 0 0 210px;
		width: 210px;
	}
}

@media (max-width: $sm-breakpoint) {
	.BookingCalendar {
		padding: 0 20px;
		grid-template-columns: 1fr !important;
		grid-template-rows: auto;
		grid-template-areas: "BookingTimePickerWrapper" "BookingCalendarContent";
		grid-gap: 0 !important;

		&.showTimePicker {
			grid-gap: 30px !important;
		}

		.BookingCalendarContent {
			margin-right: 0px;
			align-self: center;

			.SelectDateTitle {
				margin-left: 0px;
			}
		}

		.BookingTimePickerWrapper {
			padding-top: 30px;
			max-width: unset;
			display: flex;
			justify-content: center;
		}
	}
}
