@import "../../variables";

.Error400 {
	height: 100%;
	width: 100%;
	display: grid;
	place-items: center;
	text-align: center;
	margin-bottom: 50px;

	@media (max-width: $sm-breakpoint) {
		padding-top: 40px;
	}

	#timeThief {
		width: 274px;
		margin: auto;
		margin-bottom: 20px;
		pointer-events: none;
	}

	#subText400 {
		max-width: 400px;
	}

	#headerError400 {
		height: 46px;
		@media (max-width: $sm-breakpoint) {
			height: 70px;
		}
	}

	#anotherTimeButton {
		font-family: "Roboto", sans-serif;
		width: 200px;
		margin-top: 30px;
		align-self: center;
	}
}
