@import "./_variables.scss";

html {
	font-size: 100%;

	@media (max-width: $md-breakpoint) {
		font-size: 80%;
	}

	@media (max-width: $sm-breakpoint) {
		font-size: 100%;
	}
}

html,
body,
#root,
.App {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}
.App {
	display: flex;
}

.Title--xl {
	font-size: 1.5rem !important;
}

.Text {
	font-size: 0.8rem !important;
}

#mainWrap {
	width: 100%;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: max-content auto;
	grid-template-areas: "sideView mainContent";
}

#mainContent {
	grid-area: mainContent;
	width: 100%;
}

#desktopWrapper {
	width: 100%;
}

#pageWrapper {
	width: 100%;
	min-height: 100%;
	position: relative;
	display: grid;
	grid-template-rows: 1fr auto;
	grid-template-columns: 100%;
}

#sideView {
	height: 100%;
	grid-area: sideView;
	align-self: stretch;
	display: block;
	max-width: 348px;
}

#pageLoader {
	align-self: center;
	justify-self: center;
	width: 100%;
}

#confirmationLoader {
	justify-self: center;
	margin-top: 120px;
	width: 100%;
}

#poweredUpsales {
	display: flex;
	flex-direction: row;
	position: absolute;
	bottom: 2px;
	right: 17px;
	height: 32px;

	.Logo {
		font-size: 20px;
		align-self: start;
		width: 80px;
	}
	.Text {
		color: $grey-10;
		padding-right: 5px;
		padding-top: 2px;
		line-height: 18px;
	}
}

#calendarWrapper {
	padding-right: 20px;
	padding-left: 4.5vw;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: $md-breakpoint) {
		padding-left: 3vw;
	}
}

#noAvailableTimes {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: $sm-breakpoint) {
	#pageWrapper {
		grid-template-rows: auto auto;
		gap: 8px;
	}

	#mainWrap {
		grid-template-rows: min-content auto;
		grid-template-columns: 1fr;
		grid-template-areas: "sideView" "mainContent";
	}

	#mainContent {
		flex-direction: column;
		max-width: 100vw;
		margin-top: 0;
		margin-left: 0px;
	}

	#sideView {
		display: flex;
		width: 100%;
		max-width: unset;
	}

	#mobileHeader {
		display: block;
		height: 100px;
		@media (max-width: $xxs-breakpoint) {
			height: 70px;
		}
	}

	#companyLogoMobile {
		margin-left: 10px;
		margin-top: 10px;
		max-height: 28px;
		max-width: 200px;
		object-fit: contain;
	}

	#calendarWrapper {
		justify-content: center;
		padding-top: 0;
		padding-left: 0px;
		padding-right: 0px;
	}

	#poweredUpsales {
		position: static;
		margin-top: auto;
		width: 100vw;
		justify-content: center;
	}
}
